import { Controller } from '../Controller';
import {
  EVENTS_SCOPE,
  IEventsControllerActions,
  IEventsControllerState,
} from './EventsControllerProps';
import { EventStatus } from '@wix/ambassador-events-v1-event/types';

export class EventsController
  extends Controller<IEventsControllerState>
  implements IEventsControllerActions {
  protected state: IEventsControllerState = {
    events: null as any,
    limit: 100,
    offset: 0,
    total: 0,
  };

  setState(props: Partial<IEventsControllerState>) {
    this.state = { ...this.state, ...props };
    this.controllerConfig.setProps({
      [EVENTS_SCOPE]: {
        ...this.state,
        fetch: this.fetch.bind(this),
        queryIds: this.queryIds,
      },
    });

    return this.state;
  }

  pageReady(): Promise<void> {
    return Promise.resolve();
  }

  async fetch() {
    try {
      const groupId = await this.getGroupId();
      const {
        events,
        pagingMetadata,
      } = await this._api.getEventsApi().getGroupEvents(groupId!);
      const { count, offset, total } = pagingMetadata || {};
      this.setState({
        events,
        limit: count,
        offset,
        total,
        error: null as any,
      });
    } catch (error) {
      this.setState({ error: '[FAILED]: getGroupEvents' });
    }
  }

  async getInitialProps() {
    return this.setState({});
  }

  queryIds = async (ids: string[]) => {
    try {
      const {
        events,
        facets,
        pagingMetadata,
      } = await this._api.getEventsApi().queryEvents({
        query: {
          filter: {
            eventId: {
              $hasSome: ids,
            },
          },
        },
      });
      const { total } = pagingMetadata!;
      this.setState({
        events,
        total,
        error: null as any,
        statusCounts: facets?.status?.counts as {
          [key in EventStatus]: number;
        },
      });
    } catch (e) {
      this.setState({ error: '[FAILED]: queryEvents' });
    }
  };
}
